import { observable } from 'mobx'

export const USER_STORE_DEFAULT_CODE_PUSH_VERSION = '4.8.32'

const UserStore = observable({
  giveWaterCount: 10,
  itemType: 'onion',
  solvedQuizItemId: null,
  dailyPurchaseHistoryCount: null,
  farmData: {},
  guessMeFriendList: [],
  default_profile_image:
    'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png',
  orderCount: null,
  codePushVersionFromAlwayzApp: USER_STORE_DEFAULT_CODE_PUSH_VERSION,
  purchaseHistorys: [],
  cherryPickerForAd: false,
  showPuppy: false,
  pingManager: {
    dailyMission: false,
    treasureBox: false,
  },
  waterExceeded: false,
  lastSevenDaysInviteNumber: null,
  isRewardAdReady: false,
  isWatchingAd: false,
  rewardAdWatchCount: null,
  isFirstEnteredShortFormShopAd: false,
  offerwallInfo: {
    totalReward: 0,
    appId: '',
    unitId: '',
    uniqueUserId: '',
    platform: '',
    networkName: 'buzzvil',
    placement: 'ALFARM_WATER_OFFERWALL',
    ifa: '00000000-0000-0000-0000-000000000000',
    ifv: '',
    todayTotalReward: 0,
  },
  purchaseFrequency: 'MEDIUM',
  is60CherryPickingUser: false,

  adMobStatement: new Map(),

  setGuessMeFriendList(data) {
    this.guessMeFriendList = [...data]
  },

  setFarmData(data) {
    this.farmData = { ...data }
    // if (
    //   (data?.water ?? this.farmData?.water) > 3000 &&
    //   ABStore.waterMaximumTester
    // ) {
    //   this.waterExceeded = true
    // }
  },
  setGiveWaterCount(count) {
    this.giveWaterCount = count
  },
  setSolvedQuizItemId(itemId) {
    this.solvedQuizItemId = itemId
  },
  setItemType(type) {
    this.itemType = type
  },
  setDailyPurchaseHistoryCount(count) {
    this.dailyPurchaseHistoryCount = count
  },
  setOrderCount(count) {
    this.orderCount = count
  },
  setCodePushVersionFromAlwayzApp(version) {
    this.codePushVersionFromAlwayzApp = version
  },
  setPurchaseHistorys(data) {
    this.purchaseHistorys = data
  },
  setCherryPickerForAd(data) {
    this.cherryPickerForAd = data
  },
  set(key, value) {
    function setProperty(obj, keys, val) {
      let key
      while (keys.length > 1) {
        key = keys.shift()
        if (!Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
        // if (!obj.hasOwnProperty(key)) obj[key] = {}
        obj = obj[key]
      }
      obj[keys[0]] = val
    }

    if (key?.includes('.')) {
      const keys = key.split('.')
      setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },
  setLastSevenDaysInviteNumber(number) {
    this.lastSevenDaysInviteNumber = number
  },
  setIsRewardAdReady(isRewardAdReady) {
    this.isRewardAdReady = isRewardAdReady // Applovin 광고 준비 여부
  },
  setAdmobPlacementLoaded(placement) {
    this.adMobStatement.set(placement, { ready: true, placement })
  },
  setRewardAdWatchCount(count) {
    this.rewardAdWatchCount = count
  },
  clearAdmobPlacementLoaded(placement) {
    this.adMobStatement.delete(placement)
  },
  setIsWatchingAd(isWatchingAd) {
    this.isWatchingAd = isWatchingAd
  },
  setIsFirstEnteredShortFormShopAd(isFirstEnteredShortFormShopAd) {
    this.isFirstEnteredShortFormShopAd = isFirstEnteredShortFormShopAd
  },
  setOfferwallInfo(data) {
    this.offerwallInfo = data
  },
  setPurchaseFrequency(purchaseFrequency) {
    this.purchaseFrequency = purchaseFrequency
  },
  setIs60CherryPickingUser(is60CherryPickingUser) {
    this.is60CherryPickingUser = is60CherryPickingUser
  },
})

export default UserStore
